'use strict';

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faGripLines } from '@fortawesome/free-solid-svg-icons/faGripLines';
import { faRulerHorizontal } from '@fortawesome/free-solid-svg-icons/faRulerHorizontal';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';

library.add(
    faExpandArrowsAlt,
    faThLarge,
    faNewspaper,
    faMapMarker,
    faPhone,
    faFax,
    faEnvelopeOpen,
    faLink,
    faGripLines,
    faRulerHorizontal,
    faCheck,

    faFacebookF,
    faTwitter
);

dom.watch()
