'use strict'
import $ from 'jquery';

$(document).ready(function(){

    init();

    $(window).resize(function(){
        setProductTop();
    })

    function setProductTop(){
        const ww = $(window).outerWidth();
        const wh = $(window).outerHeight();
        const phh = $('#product-head').outerHeight();
        const sw = $('.product-aside').outerWidth();
        const pr = parseInt($('.product-aside').closest('.container-fluid').css('padding-right'), 10);


        if(ww > 991){
            $('#product-head').find('.data').css({
                'width' : sw + pr + 'px',
                'height' : phh + 'px'
            });
            $('#product-head').find('.image-bg').css({
                'width' : ww - sw - pr + 'px',
                'height' : phh + 'px'
            })

            const th = $('#product-head').find('.data').outerHeight();
            const tw = $('#product-head').find('.data').outerWidth();
            const ang = 21;
            const dist = th / 2 / ctg(ang * Math.PI/180);

            $('#product-head').find('.background').css({
                'width' : tw + dist * 2 + 'px',
                'left' : dist * - 1 + 'px'
            })
        }
        else{
            $('#product-head').find('.data').css({
                'width' : ww + 'px',
                'height' : 'auto'
            });

            let imageH = wh*0.5;
            if(ww*0.8 > wh){
                imageH = wh*0.7;
            }
            $('#product-head').find('.image-bg').css({
                'width' : ww + 'px',
                'height' :imageH + 'px'
            })
        }


    }

    function ctg(x) {
        return 1 / Math.tan(x);
    }

    function init(){
        setProductTop();
    }



})
