'use strict'
import $ from 'jquery';
import hammer from 'hammerjs'
import cookieconsent from 'cookieconsent';
//import fontawesome from '@fortawesome/fontawesome';

$(document).ready(function(){
    var preloadActive = false;
    setTimeout(function(){
        if(preloadActive){
            stopPreload();
        }
    }, 15000);

    function stopPreload(){
        preloadActive = false;
    }
    function init(){
        setBigButtons();
        setHeader();
        setFooter();
        checkFadeIn(0);
        if($('.fs-slider').length > 0){
            setSlider();
        }
        setLangSwitch()
    }

    $(window).scroll(function(){
        setHeader();
        checkFadeIn(1);
        //setScrollTop();
    })

    $(window).resize(function(){
        setBigButtons();
        setHeader();
        setFooter();
        if($('.fs-slider').length > 0){
            setSlider();
        }
    })


    //home big buttons

    function setBigButtons(){
        if($('.big-buttons').length > 0){
            const mh = $('.big-buttons').outerHeight();
            const mw = $('.big-buttons').outerWidth();
            if(mw > 991){
                $('.big-buttons').addClass('skew');

                const sca = 28;
                const dist = mh/ctg(sca)*-1;
                $('.big-buttons__left-wrapper').css({
                    'left' : dist * -1  + 'px',
                    'width' : mw/2 + dist + 5 + 'px'
                })
                $('.big-buttons__left-holder').css({
                    'width' : mw / 2 + 'px'
                });

                $('.big-buttons__right-wrapper').css({
                    'right' : dist * -1 + 'px',
                    'width' : mw/2 + dist + 5 + 'px'
                })
                $('.big-buttons__right-holder').css({
                    'width' : mw / 2 + 'px'
                });

                $('.big-buttons__left-wrapper').find('.background').css({
                    'left' : dist  + 'px',
                    'width' : mw/2 + dist + 'px'
                })

                $('.big-buttons__right-wrapper').find('.background').css({
                    'right' : dist + 'px',
                    'width' : mw/2 + dist + 'px'
                })
            }
            else{
                $('.big-buttons').removeClass('skew');

                $('.big-buttons__left-wrapper').css({
                    'left' : '0',
                    'width' : '100%'
                })
                $('.big-buttons__left-holder').css({
                    'width' : '100%'
                });

                $('.big-buttons__right-wrapper').css({
                    'right' : '0',
                    'width' : '100%'
                })
                $('.big-buttons__right-holder').css({
                    'width' : '100%'
                });

                $('.big-buttons__left-wrapper').find('.background').css({
                    'left' : '0',
                    'width' : '100%'
                })

                $('.big-buttons__right-wrapper').find('.background').css({
                    'right' : '0',
                    'width' : '100%'
                })
            }
        }
    }

    function ctg(x) { return 1 / Math.tan(x); }

    //language swithc

    $('.language-switch').find('li.active a').on('click', function(e){
        e.preventDefault();
    })
    $('.menu-language-switch').find('li.active a').on('click', function(e){
        e.preventDefault();
    })

    function setLangSwitch(){
        const liaw = $('.language-switch').find('li.active').outerWidth();
        const liah = $('.language-switch').find('li.active').outerHeight();
        $('.language-switch__wrapper').removeClass('hovered');
        $('.language-switch__wrapper').css({
            'width' : liaw + 'px',
            'height' : liah + 'px'
        })

        const mliaw = $('.menu-language-switch').find('li.active').outerWidth();
        const mliah = $('.menu-language-switch').find('li.active').outerHeight();
        $('.menu-language-switch__wrapper').removeClass('hovered');
        $('.menu-language-switch__wrapper').css({
            'width' : liaw + 'px',
            'height' : liah + 'px'
        })
    }

    $('.language-switch').on('mouseenter', function(){
        const ulw = $('.language-switch').find('ul').outerWidth();
        $('.language-switch__wrapper').addClass('hovered').css({
            'width' : ulw + 'px'
        })
    })

    $('.menu-language-switch').on('mouseenter', function(){
        const mulw = $('.menu-language-switch').find('ul').outerWidth();
        $('.menu-language-switch__wrapper').addClass('hovered').css({
            'width' : mulw + 'px'
        })
    })
    $('.language-switch').on('mouseleave', function(){
        setLangSwitch()
    })

    $('.menu-language-switch').on('mouseleave', function(){
        setLangSwitch()
    })

    //SCROLLTOP

    let lastScrollTop = 0;

    function setScrollTop(){
        const wst = $(window).scrollTop();
        if(wst < lastScrollTop && wst > 1000){
            $('#scroll-top').addClass('isvis');
        }
        else{
            $('#scroll-top').removeClass('isvis');
        }
        lastScrollTop = wst;
    }

    $('#scroll-top').on('click', function(e){
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 500, function(){
            setTimeout(function(){
                $('#scroll-top').removeClass('isvis');
            }, 100);
        });
    })

    //HEADER
    let headerIsTiny = false;
    function setHeader(){
        var topOffset = $(window).scrollTop();
        if(topOffset > 10){
            $('header').addClass('tiny');
            $('.mobile-nav').addClass('tiny');
            headerIsTiny = true;
            /*
            hidePrimaryNav();
            if(secondaryNavCollapsed === false){
                $('.menu-icon').removeClass('open');
                hideSecondaryNav();
            }
            */
        }
        else{
            $('header').removeClass('tiny');
            $('.mobile-nav').removeClass('tiny');
            headerIsTiny = false;
            //setPrimaryNav()
        }

    }

    //FOOTER
    function setFooter(){
        var fHeight = $('footer').outerHeight();
        $('.page-wrapper').css('padding-bottom', fHeight + 'px');
    }

    //SLIDER

    var defSlider = $.Deferred();

    function setSlider(){
        var pl = parseInt($('.fs-slider').css('padding-left'), 10);
        var sw = $('.fs-slider').outerWidth();
        var wh = $(window).height();
        var ww = $(window).width();
        var hh = $('header').outerHeight();
        var sh = wh;
        $('.fs-slider').css({
            'height' : sh + 'px',
            'margin-bottom' : pl + 'px'
        })
    }

    //if not loaded - hide preloader
    setTimeout(function(){
        defSlider.resolve();
    }, 15000);

    //hide preloader
    $.when(defSlider).done(function () {
        if(!$('#items-container').length){
            $('#slider-preloader').fadeOut(500);
            $('body').removeClass('scroll-locked');
        }
    });

    // start preloader if there are slides
    var countSlides = $('.fs-slider__slides').find('.slide').length;
    if(countSlides > 0){
        preloadSlider();
    }
    else{
        defSlider.resolve();
    }

    //slider triggers from dots switch
    $(document).on('click', '.slider-switch li a', function(e){
       e.preventDefault();
       if(sliderAutoChange){
           stopAC();
       }

       if(!$(this).closest('li').hasClass('active')){
           var sNum = $(this).closest('li').index();
           getSlide(sNum)
       }
       else{
           return;
       }
    })

    //slider touch triggers

    function initSliderHammer(){
       var hElement = document.getElementById('home-slider');
       var hammertime = new Hammer(hElement);
       hammertime.on('swipeleft', function() {
           if(sliderAutoChange){
               stopAC();
           }
           getPrevSlide();
       });
       hammertime.on('swiperight', function() {
           if(sliderAutoChange){
               stopAC();
           }
           getNextSlide();
       });
    }


    //slider preload
    function preloadSlider(){
       if(countSlides > 0){
           //get first slide url. For video image is required
           var slide = $('.fs-slider__slides').find('.slide').eq(0);
           var c = new Image();
           var slideUrl = slide.attr("data-bg-image");

           slideUrl = slideUrl.replace('url(','').replace(')','').replace(/\"/gi, "");
           //first slide image is loaded
           c.onload = function(){
               //resolve deffered, set first slide and get it via function
               slide.css({
                   'background-image' : 'url('+slideUrl+')'
               })
               defSlider.resolve();
               setSliderControlDots();
               getSlide(0);

               //load more slides if exist
               if(countSlides > 1){
                   $('.fs-slider__slides').find('.slide').each(function(){
                       var slide = $(this);
                       var sind = slide.index();
                       if(sind > 0){
                           var slideUrl = slide.attr("data-bg-image");
                           slideUrl = slideUrl.replace('url(','').replace(')','').replace(/\"/gi, "");
                           var c = new Image();
                           c.onload = function(){
                               //if slide is loaded, add background and add loaded class
                               slide.css({
                                   'background-image' : 'url('+slideUrl+')'
                               })
                               slide.addClass('loaded');

                               //after all slides are loaded - show slide switch and init slidehammer
                               if(sind + 1 == countSlides){
                                   $('.slider-switch').addClass('vis');
                                   initSliderHammer();
                                   //console.log('all slides loaded')
                               }
                           }
                           c.src = slideUrl;
                       }
                   })
               }
               else{
                   return;
               }
           }
           c.src = slideUrl;
       }
       else{
           return;
       }
    }


    // set slide switch functions
    function setSliderControlDots(){
        $('.fs-slider').find('.slide').each(function(index){
            var num = ("0" + (index + 1)).slice(-2);
            $('.slider-switch').append('<li><a href="#"></a></li>')
        })
    }

    //directional get slide functions
    function getNextSlide(){
       var as = $('.fs-slider').find('.slide.active').index();
       getSlide(as + 1);
    }
    function getPrevSlide(){
       var as = $('.fs-slider').find('.slide.active').index();
       getSlide(as - 1);
    }


    // main get slide function
    function getSlide(sNum){

        // detect if needed slide exists
       if(sNum + 1 > countSlides){
           sNum = 0;
       }
       if(sNum < 0){
           sNum = countSlides - 1;
       }

       var asNum = $('.slider-switch').find('li.active').index();


       // remove active from active slide and from switch
       $('.fs-slider__slides').find('.slide').removeClass('active');

       if(asNum < sNum){
            $('.slider-switch').find('li.active').removeClass('active').addClass('pb');

       }
       else{
           $('.slider-switch').find('li.active').removeClass('active');
           $('.slider-switch').find('li').eq(sNum).addClass('na').addClass('pb');
       }



       setTimeout(function(){
            $('.slider-switch').find('li').eq(sNum).removeClass('na').addClass('active');
            $('.slider-switch').find('li').removeClass('pb');
       },200)

       //get required slide
       var slide = $('.fs-slider__slides').find('.slide').eq(sNum);


       //activate slide and slid switch
       slide.addClass('active');

       //autochange
       if(sliderAutoChangeTriggered === false){
           stopAC();
           $.when(defSlider).done(function () {
               sliderAutoChangeTriggered = true;
               setTimeout(startAC, 10000);
           });
       }
    }


    //slider autochange
    var sliderAutoChange;
    var sliderAutoChangeTriggered = false;
    function startAC(){
        console.log('ac started')
       sliderAutoChangeTriggered = true;
       sliderAutoChange = setInterval(function(){
           console.log('get next slide')
           getNextSlide()
       }, 10000);
    }
    function stopAC(){
       clearTimeout(sliderAutoChange);
       sliderAutoChangeTriggered = false;
       sliderAutoChange = false;
    }


    //MOBILE NAV

    function setMobileNav(){
        const fwlp = 50;
        let primItemMaxWidth = 0;
        let secondMenuMaxWidth = 0;
        let allowHoriz = true;
        let allowSemihoriz = true;
        const ww = $('.mobile-nav__wrapper').width();
        $('.mobile-nav__primary').children('li').each(function(e){
            const thisW = $(this).find('a:first-child').outerWidth(true);
            let fw = 0;
            if(thisW > primItemMaxWidth){
                primItemMaxWidth = thisW;
            }
        })
        $('.mobile-nav__primary').children('li').css({
            'width' : primItemMaxWidth + fwlp + 'px'
        })


        $('.mobile-nav__secondary').each(function(e){
            let fw = 0;
            $(this).find('li').each(function(){
                const tw = $(this).outerWidth(true);
                fw = fw + tw
            })

            if(fw > secondMenuMaxWidth){
                secondMenuMaxWidth = fw;
            }
        })


        if(primItemMaxWidth + fwlp + secondMenuMaxWidth > ww){
            allowHoriz = false;
            $('.mobile-nav__primary').removeClass('fw')
        }
        else{
            allowHoriz = true;
            $('.mobile-nav__primary').addClass('fw')
            $('.mobile-nav__primary').css({
                'width' : primItemMaxWidth + fwlp + 'px'
            })
        }

        if (secondMenuMaxWidth > ww) {
            allowSemihoriz = false;
            $('.mobile-nav__primary').removeClass('sfw')
        }
        else{
            allowSemihoriz = true;
            if(allowHoriz === false){
                $('.mobile-nav__primary').addClass('sfw')
            }
            else{
                $('.mobile-nav__primary').removeClass('sfw')
            }
        }

        const wrapH = $('.mobile-nav__wrapper').outerHeight();
        const mnH = $('.mobile-nav__primary').outerHeight();
        if(wrapH > mnH){
            $('.mobile-nav__wrapper').css({
                'padding-top' : (wrapH - 100 - mnH)/2 + 'px'
            })
        }

        console.log('Horiz: ' + allowHoriz + ' and Semihoriz: ' +allowSemihoriz )


    }


    $('.menu-icon').on('click', function(e){
        e.preventDefault();
        $(this).toggleClass('open');
        if($('.navigation-holder').hasClass('open')){
            hideMobileNav();
        }
        else{
            showMobileNav();
        }
    })

    function showMobileNav(){
        $('.navigation-holder').addClass('open');
        $('body').addClass('scroll-locked');
        $('header').find('.head-right').addClass('hidden');
        if(headerIsTiny === true){
            $('header').removeClass('tiny');
        }
        else{

        }
    }
    function hideMobileNav(){
        $('.navigation-holder').removeClass('open');
        $('body').removeClass('scroll-locked');
        $('header').find('.head-right').removeClass('hidden');
        if(headerIsTiny === true){
            $('header').addClass('tiny');
        }
        else{

        }
    }


    //HOME tiles

    function initHomeTiles(){
        if($('.home-tiles').length > 0){
            $('.home-tiles').find('.tile .tile__description').slideUp(200);
            $('.home-tiles').find('.tile .tile__link').slideUp(200);
        }
    }

    $('.home-tiles').find('.tile').on('mouseenter', function(){
        $(this).find('.tile__description').stop().slideDown(200);
        $(this).find('.tile__link').stop().slideDown(200);
    })
    $('.home-tiles').find('.tile').on('mouseleave', function(){
        $(this).find('.tile__description').stop().slideUp(200);
        $(this).find('.tile__link').stop().slideUp(200);
    })

    function initBlogSnippets(){
        if($('.blog-snippets').length > 0){
            $('.blog-snippets').find('.snippet .snippet__description').slideUp(200);
            $('.blog-snippets').find('.snippet .snippet__link').slideUp(200);
        }
    }

    $('.blog-snippets').find('.snippet').on('mouseenter', function(){
        $(this).find('.snippet__description').stop().slideDown(200);
        $(this).find('.snippet__link').stop().slideDown(200);
    })
    $('.blog-snippets').find('.snippet').on('mouseleave', function(){
        $(this).find('.snippet__description').stop().slideUp(200);
        $(this).find('.snippet__link').stop().slideUp(200);
    })

    init();


    //fadeins

    function checkFadeIn(m){
        var st = $(document).scrollTop();
        var wh = $(window).height();
        var co = st + wh;


        if(m == 0){
            $('body').find('.h').each(function(){
                var el = $(this)
                var th = el.outerHeight(true);
                var to = el.offset().top;
                if(to < co + 50){
                    showBlock(el)
                }
                else{
                    return false;
                }
            })
        }

        if(m == 1){
            var time = 0;
            $('body').find('.h').each(function(){
                var el = $(this);

                setTimeout(function() {
                    var to = el.offset().top;
                    if(to < co -50){
                        showBlock(el)
                    }
                }, time);
                time += 75;
            })

        }

        function showBlock(el) {
            el.removeClass('h');
        }
    }



})
