'use strict'
import $ from 'jquery';
import cookieconsent from 'cookieconsent';

$(document).ready(function(){
    var ctext = $('#cookies-text').html();
    var cok = $('#cookies-ok').html();
    window.addEventListener("load", function(){
    window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#f7f7f7"
      },
      "button": {
        "background": "#A90B3C"
      }
    },
    "position": "bottom-left",
    "content": {
      "message": ctext,
      "dismiss": cok,
      "link": "",
      "href": ""
    }
    })});
})